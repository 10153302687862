import "./CoursePaymentForm.css";
import {TextInput} from "@mantine/core";
import {createRef} from "react";
import PhoneInputField from "../../../components/PhoneInputField/PhoneInputField";

const CoursePaymentForm = ({form, isRegistered, setIsRegistered}) => {
    const ref = createRef()

    return (
        <div className="coursePaymentForm-father-div">
            <div className="coursePaymentForm-father-container">
                <h3 className="coursePaymentForm-description-text">
                    Completa la información para crear el perfil educativo
                </h3>
                <p className="coursePaymentForm-already-have-account" onClick={() => {setIsRegistered(!isRegistered)}}>
                    {isRegistered ? "Aún no tengo cuenta en FORVET" : "¡Ya tengo una cuenta en ForVet!"}
                </p>
                <div className="coursePaymentForm-form-div">
                    <form className="coursePaymentForm-form-styling" onSubmit={form.onSubmit(console.log)}>
                        {!isRegistered &&
                        <TextInput
                            classNames={{
                                root: "coursePaymentForm-first-textInput",
                                label: "coursePaymentForm-textInput-labels",
                                wrapper: "coursePaymentForm-textInput-wrapper",
                                error: "coursePaymentForm-textInput-error",
                            }}
                            label="Nombre"
                            placeholder="Nombre"
                            {...form.getInputProps("name")}
                            onBlur={(event) =>
                                form.setFieldValue("name", event.currentTarget.value)
                            }
                        />
                        }
                        {!isRegistered &&
                        <TextInput
                            classNames={{
                                root: "coursePaymentForm-second-textInput",
                                label: "coursePaymentForm-textInput-labels",
                                wrapper: "coursePaymentForm-textInput-wrapper",
                                error: "coursePaymentForm-textInput-error",
                            }}
                            label="Apellido"
                            placeholder="Apellido"
                            {...form.getInputProps("apellido")}
                            onBlur={(event) =>
                                form.setFieldValue("apellido", event.currentTarget.value)
                            }
                        />
                        }
                        <TextInput
                            classNames={{
                                root: "coursePaymentForm-third-textInput",
                                label: "coursePaymentForm-textInput-labels",
                                wrapper: "coursePaymentForm-textInput-wrapper",
                                error: "coursePaymentForm-textInput-error",
                            }}
                            mt="sm"
                            label="Email de registro al campus"
                            placeholder="Email"
                            {...form.getInputProps("email")}
                            onBlur={(event) =>
                                form.setFieldValue("email", event.currentTarget.value)
                            }
                        />
                        <TextInput
                            classNames={{
                                root: "coursePaymentForm-third-textInput",
                                label: "coursePaymentForm-textInput-labels",
                                wrapper: "coursePaymentForm-textInput-wrapper",
                                error: "coursePaymentForm-textInput-error",
                            }}
                            mt="sm"
                            label="Confirmación de email de registro al campus"
                            placeholder="Email"
                            {...form.getInputProps("emailConfirmation")}
                            onBlur={(event) =>
                                form.setFieldValue("emailConfirmation", event.currentTarget.value)
                            }
                        />
                        {!isRegistered &&
                        <PhoneInputField
                            ref={ref}
                            name="telefono"
                            fieldError={form.errors.telefono}
                            {...form.getInputProps("telefono")}
                            onChange={(value) => {
                                form.setFieldValue("telefono", value)
                            }}
                        />
                        }
                    </form>
                </div>
                <p className="coursePaymentForm-note">
                    Los datos personales proporcionados en este formulario serán utilizados exclusivamente para la
                    creación de tu cuenta de usuario en nuestra plataforma educativa. Si ya posees una cuenta previa,
                    por favor ingresa el correo electrónico asociado a la misma para vincular el curso a tu perfil
                    existente.
                </p>
                <p className="coursePaymentForm-note">
                    Es importante destacar que el individuo que realiza el pago no necesita ser necesariamente el mismo
                    usuario que accederá al curso. Los detalles de facturación y pago se solicitarán en la plataforma de
                    pago externa. Los datos requeridos en este formulario son fundamentales para el registro y
                    asociación del curso al usuario correspondiente.
                </p>
            </div>
        </div>
    );
};

export default CoursePaymentForm;
