import React, {useState} from "react";
import CoursePaymentCard from "../../features/CoursePaymentPage/CoursePaymentCard/CoursePaymentCard";
import CoursePaymentForm from "../../features/CoursePaymentPage/CoursePaymentForm/CoursePaymentForm";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import {useForm} from "@mantine/form";
import {useAppContext} from "../../context/AppState/AppContext";
import axios from "axios";
import "./CoursePaymentPage.css";
import {openUrl} from "../../utils/openUrl";
import {useNavigate} from "react-router-dom";
import showNotification from "../../utils/showNotification";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import {isValidPhoneNumber} from "react-phone-number-input";
import DecorativeCircle from "../../components/DecorativeCircle/DecorativeCircle";
import TinyDecorativeCircle from "../../components/TinyDecorativeCircle/TinyDecorativeCircle";
import {calculateDiscountedPrice} from "../../utils/calculateDiscountedPrice";

function validateEmail(_email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailPattern.test(_email.trim())) {
        return null;
    } else {
        return "E-mail inválido";
    }
}

const CoursePaymentPage = () => {
    const [discountInfo, setDiscountInfo] = useState({
        percentage: 0,
        active: false,
        code: "",
        _id: "",
    });
    const [isRegistered, setIsRegistered] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const {paymentData, setPaymentData, isUSD} = useAppContext();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);

    const form = useForm({
        validateInputOnChange: true,
        initialValues: {
            name: "",
            email: "",
            emailConfirmation: "",
            apellido: "",
            telefono: "",
        },
        validate: {
            name: (value) => {
                if (isRegistered) return false;
                const isValid = /^[a-zA-Z\s]+$/.test(value);
                return isValid && value.length >= 2
                    ? null
                    : "No debe tener al menos 2 letras y no contener puntos, guiones ni comas";
            },
            email: (value) => {
                return validateEmail(value);
            },
            emailConfirmation: (value) => {
                return validateEmail(value);
            },
            apellido: (value) => {
                if (isRegistered) return false;
                const isValid = /^[a-zA-Z\s]+$/.test(value);
                return isValid && value.length >= 2
                    ? null
                    : "No debe tener al menos 2 letras y no contener puntos, guiones ni comas";
            },
            telefono: (value) => {
                if (isRegistered) return false;
                if (typeof value === "string")
                    return !isValidPhoneNumber(value)
                else return true
            }
        },
    });

    const handlePaymentMethodChange = (id) => {
        setSelectedPaymentMethod(id);
    };

    const buildPostData = () => {
        const {id, name, price} = paymentData;
        const postData = {
            data: {
                description: name, // Course name
                amount: price,
                agreedAmount: price,
                name: form.values.name.trim(),
                lastname: form.values.apellido.trim(),
                email: form.values.email.toLowerCase().trim(),
                phone: form.values.telefono.trim(),
                academicUnitId: id,
                discount: null,
                currency: isUSD ? 'USD' : 'ARS'
            },
        };

        // If discount is active, add it to the post data
        if (discountInfo.active) {
            postData.data.agreedAmount = calculateDiscountedPrice(price, discountInfo.percentage);
            postData.data.discount = {
                _id: discountInfo._id,
                percent: discountInfo.percentage,
                amount: price - postData.data.agreedAmount,
            };
        }

        // If the user is already registered, add a flag to the post data
        if (isRegistered) {
            postData.data.is_registered = true;
        }

        return postData;
    }

    async function handleSubscriptionPayment(_data, _selectedSubscriptionPlan) {
        // Add subscription plan data to post data
        _data.data.subscriptionPlanId = _selectedSubscriptionPlan;
        _data.data.subscriptionData = paymentData.subscriptionPlans.find(
            (plan) => plan.subscriptionPlanId === _selectedSubscriptionPlan
        );
        if (!isUSD) {
            // Since the subscription is in USD, we need to transform everything to USD
            const subscriptionAmount = _data.data.subscriptionData.amount_per_cycle * _data.data.subscriptionData.total_cycles;
            _data.data.currency = "USD";
            _data.data.amount = subscriptionAmount;
            _data.data.agreedAmount = subscriptionAmount;
            if (discountInfo.active) {
                _data.data.agreedAmount = calculateDiscountedPrice(subscriptionAmount, discountInfo.percentage);
                _data.data.discount = {
                    ..._data.data.discount,
                    amount: subscriptionAmount - _data.data.agreedAmount,
                };
            }
        }

        // Today, it only supports PayPal
        return axios.post(
            `${process.env.REACT_APP_BACKEND_SERVER}/payment/subscription/paypal`,
            _data
        );
    }

    async function handleUniquePayment(_data) {
        return axios.post(
            `${process.env.REACT_APP_BACKEND_SERVER}/payment/${selectedPaymentMethod}`,
            _data
        );
    }

    const handlePaymentSubmit = async (selectedSubscriptionPlan) => {
        const isValid = form.isValid();
        if (form.values.email !== form.values.emailConfirmation) {
            form.setFieldError("emailConfirmation", "Los e-mails no coinciden");
            return;
        }
        if (isValid && selectedPaymentMethod !== null) {
            const postData = buildPostData();

            const timestamp = new Date().getTime();
            setPaymentData({...paymentData, postData, timestamp});

            if (selectedPaymentMethod === "transfer") {
                navigate("/transferencia-bancaria");
                return;
            }

            try {
                setLoading(true);

                let response;
                if (selectedSubscriptionPlan !== "UNIQUE_PAYMENT") {
                    response = await handleSubscriptionPayment(postData, selectedSubscriptionPlan);
                } else {
                    response = await handleUniquePayment(postData);
                }

                openUrl(response.data.data, false);
            } catch (error) {
                console.error("Error al procesar el pago:", error);
            } finally {
                setLoading(false);
            }
        } else {
            showNotification({
                color: "red",
                status: "error",
                title: "Pago no procesado.",
                message: `Debe completar sus datos y seleccionar un medio de pago para avanzar.`,
            });
        }
    };

    return (
        <MainLayout overflow="hidden">
            <section className="coursePaymentPage-section" style={{position: "relative"}}>
                <SectionTitle upText='¿Todo listo?' downText='Un último paso para formar parte de la comunidad'/>
                <DecorativeCircle size={100} top={-20} left={-20} opacity={0.2} shadow={1}/>
                <DecorativeCircle size={150} bottom={-220} left={300} opacity={0.2} shadow={1}/>
                <DecorativeCircle size={50} top={-20} left={800} opacity={0.2} shadow={4}/>
                <DecorativeCircle size={100} top={0} right={-50} opacity={0.5} shadow={2}/>
                <TinyDecorativeCircle size={12} top={50} right={350} opacity={1} color={1}/>
                <TinyDecorativeCircle size={12} top={300} left={70} opacity={0.75} color={1}/>
                <TinyDecorativeCircle size={32} top={80} left={500} opacity={1} color={3}/>
            </section>
            <div className="coursePaymentPage-layout coursePaymentPage-section">
                <div className="coursePaymentPage-main">
                    <CoursePaymentForm
                        form={form}
                        isRegistered={isRegistered}
                        setIsRegistered={setIsRegistered}
                    />
                </div>
                <aside className="coursePaymentPage-aside">
                    <CoursePaymentCard
                        loadingButton={loading}
                        selectedPaymentMethod={selectedPaymentMethod}
                        onPaymentMethodChange={handlePaymentMethodChange}
                        onSubmit={handlePaymentSubmit}
                        discountInfo={discountInfo}
                        setDiscountInfo={setDiscountInfo}
                        paymentDataId={paymentData.id}
                        paymentDataName={paymentData.name}
                        paymentDataImage={paymentData.image}
                        paymentDataPrice={paymentData.price}
                        subscriptionPlansPayPal={paymentData.subscriptionPlans}
                    />
                </aside>
            </div>
        </MainLayout>
    );
};

export default CoursePaymentPage;
