import {TextInput, Button, Group, Radio, Select} from "@mantine/core";
import {useForm} from "@mantine/form";
import axios from "axios";
import {useState} from "react";
import "./CoursePaymentCard.css";
import {currencyFormat} from "../../../utils/currencyFormat";
import {formattedPrice} from "../../../utils/formatted-price";
import {useAppContext} from "../../../context/AppState/AppContext";
import {calculateDiscountedPrice} from "../../../utils/calculateDiscountedPrice";

/**
 * Calculate the price for UNIQUE_PAYMENT in USD
 * @param price
 * @param discount
 * @param isUSD
 * @param officialUSDValue
 * @returns {*} price in USD
 */
const calculateOnePaymentPriceInUSD = (price, discount, isUSD, officialUSDValue) => {
    const usdPrice = isUSD ? price : price / officialUSDValue;
    return calculateDiscountedPrice(usdPrice, discount);
};
/**
 * Creates a label to describe the subscription plan
 * @param cycles - number of cycles
 * @param amount - amount per cycle
 * @param discount - discount percentage
 * @returns {`${string} cuotas de ${string}`}
 */
const formatSubscriptionLabel = (cycles, amount, discount) =>
    `${cycles} cuotas de ${formattedPrice(calculateDiscountedPrice(amount, discount), true)}`;


const CoursePaymentCard = ({
                               loadingButton,
                               selectedPaymentMethod,
                               onPaymentMethodChange,
                               onSubmit,
                               discountInfo,
                               setDiscountInfo,
                               paymentDataName,
                               paymentDataImage,
                               paymentDataPrice,
                               paymentDataId,
                               subscriptionPlansPayPal
                           }) => {
    const [loading, setLoading] = useState(false);
    const [discountApplied, setDiscountApplied] = useState(false);
    const [discountError, setDiscountError] = useState(null);
    const {width, isUSD, officialUSDValue} = useAppContext()
    const isMobile = width < 1000;
    const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState("UNIQUE_PAYMENT");

    // This are the subscription plans for the course
    const paymentPriceInUSD = calculateOnePaymentPriceInUSD(paymentDataPrice, discountInfo.percentage, isUSD, officialUSDValue);
    const subscriptionPlansData = [
        {
            value: 'UNIQUE_PAYMENT',
            label: 'Un solo pago de: ' + formattedPrice(paymentPriceInUSD, true),
        },
        ...subscriptionPlansPayPal.map((plan) => ({
            value: plan.subscriptionPlanId,
            label: formatSubscriptionLabel(plan.total_cycles, plan.amount_per_cycle, discountInfo.percentage),
        }))
    ];

    const form = useForm({initialValues: {discountCode: ""}});
    const {values, ...formProps} = form;

    const handleApplyDiscount = async () => {
        try {
            setLoading(true);

            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_SERVER}/info/checkDiscountCode`,
                {code: values.discountCode,
                 id: paymentDataId
                }
            );

            const discountInfoData = response.data.data;

            if (discountInfoData && discountInfoData.active) {
                setDiscountInfo(discountInfoData);
                setDiscountApplied(true);
                setDiscountError(null);
            } else {
                setDiscountInfo({
                    percentage: 0,
                    active: false,
                    code: "",
                    message: "",
                });
                setDiscountApplied(false);
                setDiscountError("Código de descuento inválido");
            }
        } catch (error) {
            console.error("Error fetching discount data:", error);
            setDiscountError("Código de descuento inválido");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="coursePaymentCard-father-div">
            <div className="coursePaymentCard-image-container">
                <img
                    className="coursePaymentCard-image-styling"
                    src={paymentDataImage}
                    alt="payment data"
                />
            </div>

            <div className="coursePaymentCard-paymentDetails-container">
                <h4 className="coursePaymentCard-course-title">{paymentDataName}</h4>
                <h3 className="coursePaymentCard-price-value">
                    {formattedPrice(currencyFormat(paymentDataPrice), isUSD)}
                </h3>
            </div>

            <div>
                <hr className="coursePaymentCard-divisorHr-styling"/>
                <div className="coursePaymentCard-firstHr-div-container">
                    <form className="coursePaymentCard-form-styling" {...formProps}>
                        <div className="coursePaymentCard-textInput-div">
                            <TextInput
                                classNames={{label: "coursePaymentCard-textInput-label"}}
                                label="Código de descuento"
                                placeholder="Código de descuento"
                                {...form.getInputProps("discountCode")}
                            />
                            {discountError && (
                                <p className="coursePaymentCard-error-message">
                                    {discountError}
                                </p>
                            )}
                        </div>
                        <div className="coursePaymentCard-firstButton-div">
                            <Button
                                fw="normal"
                                fullWidth
                                type="button"
                                onClick={handleApplyDiscount}
                                loading={loading}
                                disabled={discountApplied}
                            >
                                {isMobile ? "Aplicar" : "Aplicar descuento"}
                            </Button>
                        </div>
                    </form>
                </div>
                <hr className="coursePaymentCard-divisorHr-styling"/>
                <div className="coursePaymentCard-divisorHr-subtotal">
                    <p className="coursePaymentCard-subtotal-p">Subtotal</p>
                    <p className="coursePaymentCard-firstHr-second-p-styling">
                        {discountApplied
                            ? `$ ${currencyFormat(calculateDiscountedPrice(paymentDataPrice, discountInfo.percentage))}`
                            : `$ ${currencyFormat(paymentDataPrice)}`}
                    </p>
                </div>
                <hr className="coursePaymentCard-divisorHr-styling"/>
                <div className="coursePaymentCard-secondHr-div-container">
                    <p className="coursePaymentCard-total-p">Total</p>
                    <p className="coursePaymentCard-secondHr-second-p-styling">
                        {discountApplied
                            ? `$ ${currencyFormat(calculateDiscountedPrice(paymentDataPrice, discountInfo.percentage))}`
                            : `$ ${currencyFormat(paymentDataPrice)}`}
                    </p>
                </div>
                <hr className="coursePaymentCard-divisorHr-styling"/>
            </div>

            <div className="coursePaymentCard-selector-container">
                <Radio.Group
                    value={selectedPaymentMethod}
                    onChange={(id) => onPaymentMethodChange(id)}
                >
                    <Group mt="xs">
                        <Radio
                            fw="normal"
                            value="transfer"
                            label="Transferencia bancaria directa"
                            description="Realiza tu pago directamente en nuestras cuentas bancarias. Por favor, usa el número del pedido como referencia de pago. Tu pedido no se procesará hasta que se haya recibido el importe en nuestra cuenta."
                            classNames={{
                                label: "coursePaymentCard-checkbox-label",
                                description: "coursePaymentCard-checkbox-description",
                            }}
                        />
                        <Radio
                            fw="normal"
                            value="pagos360"
                            label="Pagos360"
                            description="Aproveche la velocidad y seguridad de Pagos360 al completar su transacción de pago. Esta plataforma confiable facilita el proceso, brindándole tranquilidad y eficiencia en cada transacción."
                            classNames={{
                                label: "coursePaymentCard-checkbox-label",
                                description: "coursePaymentCard-checkbox-description",
                            }}
                        />
                        <Radio
                            fw="normal"
                            value="paypal"
                            label="PayPal"
                            description="Opte por la comodidad y seguridad al pagar con PayPal. La plataforma líder en transacciones en línea le ofrece una experiencia sin problemas, permitiéndole realizar pagos de manera fácil y segura con solo unos clics. (Los pagos se realizan en USD)"
                            classNames={{
                                label: "coursePaymentCard-checkbox-label",
                                description: "coursePaymentCard-checkbox-description",
                                root: "coursePaymentCard-thirdCheckbox-root",
                            }}
                        />
                        {subscriptionPlansPayPal.length > 1 && selectedPaymentMethod === "paypal" && (
                            <div className="coursePaymentCard-paypal-plans-container">
                                <Select data={subscriptionPlansData}
                                        value={selectedSubscriptionPlan}
                                        onChange={setSelectedSubscriptionPlan}
                                        allowDeselect={false}
                                        styles={{root: {width: "100%"}}}/>
                            </div>
                        )}
                    </Group>
                </Radio.Group>
            </div>

            <div className="coursePaymentCard-button-container">
                <Button
                    onClick={() => onSubmit(selectedSubscriptionPlan)}
                    fw="normal"
                    fullWidth
                    color="secondaryColor"
                    loading={loadingButton}
                >
                    Realizar Pago
                </Button>
            </div>
        </div>
    );
};

export default CoursePaymentCard;
