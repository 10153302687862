import {Button} from "@mantine/core";
import "./PaymentPage.css";
import PaymentPageLogo from "../PaymentPageLogo/PaymentPageLogo";
import PaymentPageConfirmed
    from "../../../assets/PaymentPageCheckout/amistoso-perro-basenji-inteligente-dando-su-pata-cerca-aislado-blanco 2.png";
import PaymentFailedImage from "../../../assets/PaymentPageCheckout/sad-dog-payment.png";
import {useNavigate, useLocation} from "react-router";

const PaymentPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const status = searchParams.get("status");
    const operationCode = searchParams.get("operation");
    const HandleButton = () => {
        navigate("/cursos");
    };
    const HandleButtonContact = () => {
        navigate("/contacto");
    }

    const imageToShow = status === "success" ? PaymentPageConfirmed : PaymentFailedImage;

    const renderContent = () => {
        if (status === "success") {
            return (
                <div className="paymentPage-father-div">
                    <div className="paymentPage-inscription-confirmed-div">
                        <div className="paymentPage-image-container-div">
                            <PaymentPageLogo imageUrl={imageToShow}/>
                        </div>
                        <div className="paymentPage-inscription-details-div">
                            <h3 className="paymentPage-confirmation-legend">
                                ¡Gracias por tu pedido!
                            </h3>
                            <p className="paymentPage-description-legend">
                                Estamos procesando tu pago. Pronto recibirás en tu correo
                                electrónico los pasos a seguir. ¡Bienvenido!
                            </p>
                            <div className="paymentPage-operation-div">
                                <p className="paymentPage-operation-code">Tu código de seguimiento es: </p>
                                <hr/>
                                <h3 className="paymentPage-operation-code-span">{operationCode}</h3>
                                <hr/>
                            </div>
                            <p className="paymentPage-questions"> Si te quedó alguna duda, o tenes alguna consulta, no
                                dudes en ponerte en contacto con nuestro equipo de soporte. </p>
                        </div>
                    </div>

                    <div className="paymentPage-button-div">
                        <Button fw="normal" onClick={HandleButton}>
                            Seguir explorando cursos
                        </Button>
                        <Button variant="outline" classNames={{root: 'paymentPage-firstButton-root'}} fw="normal"
                                onClick={HandleButtonContact}>
                            Contáctanos
                        </Button>
                    </div>
                </div>
            );
        }

        // Si no es "success", asumir "cancel"
        return (
            <div className="paymentPage-father-div">
                <div className="paymentPage-inscription-confirmed-div">
                    <div className="paymentPage-image-container-div">
                        <PaymentPageLogo imageUrl={imageToShow} showSadImage={status !== "success"}/>
                    </div>
                    <div className="paymentPage-inscription-details-div">
                        <h3 className="paymentPage-confirmation-legend">
                            ¡Ups! Parece que algo salió mal con tu pago.
                        </h3>
                        <p className="paymentPage-description-legend">
                            ¡No te desanimes! ¡Tenemos otras formas de pago que puedes
                            aprovechar para no perderte esta oportunidad única!
                        </p>
                        <p className="paymentPage-questions"> Comunicate con el equipo de soporte para cualquier
                            información o inconveniente. </p>
                    </div>
                </div>

                <div className="paymentPage-button-div">
                    <Button classNames={{root: 'paymentPage-firstButton-root'}} fw="normal"
                            onClick={HandleButtonContact}>
                        Contáctanos
                    </Button>
                    <Button fw="normal" onClick={HandleButton}>
                        Seguir explorando cursos
                    </Button>
                </div>
            </div>
        );
    };

    return <div className="paymentPage-father-div">{renderContent()}</div>;
};

export default PaymentPage;