import React, {createRef, useState} from 'react';
import {Button, Group, SimpleGrid, Textarea, TextInput} from "@mantine/core";
import { useForm } from '@mantine/form';
import './ContactForm.css';
import axios from "axios";
import showNotification from "../../../utils/showNotification";
import {isValidPhoneNumber} from "react-phone-number-input";
import PhoneInputField from "../../../components/PhoneInputField/PhoneInputField";


function ContactForm({courseData}) {
    const [isLoading, setIsLoading] = useState(false);
    const ref = createRef()
    const form = useForm({
        initialValues: {
            name: '',
            email: '',
            message: '',
            phone: '',
        },

        validate: {
            name: (value) => (value.length > 5 ? null : 'Ingrese su nombre completo'),
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'El email ingresado no es valido'),
            message: (value) => (value.length > 10 ? null : 'El mensaje debe tener al menos 10 caracteres'),
            phone: (value) => {
                if (typeof value === "string")
                    return !isValidPhoneNumber(value)
                else return true
            }
        },
    });

    const handleFormSubmit = async (values) => {
        setIsLoading(true);

        // Send message to slack
        let message = `Mensaje enviado por ${values.name} - email: ${values.email} - celular: ${values.phone} --- ${values.message}`;
        if (courseData) {
            message += ` --- ID del curso: ${courseData.id} - Nombre del curso: ${courseData.name}`;
        }
        const data = {
            channel: 'web-soporte',
            message
        }

        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_SERVER}/info/slack`, data)
            showNotification({
                color: 'green',
                status: 'success',
                title: 'Operación exitosa.',
                message: `Su mensaje ha sido enviado correctamente.`
            });
        } catch (error) {
            showNotification({
                color: 'red',
                status: 'error',
                title: 'Operación fallida.',
                message: `Detalles: ${error}`,
            })
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <form onSubmit={form.onSubmit((values) => handleFormSubmit(values))}>
            <SimpleGrid cols={{ base: 1, lg: 2 }}
                        style={{marginBottom: '8px'}}>
                <TextInput
                    label="Nombre"
                    placeholder="Nombre"
                    disabled={isLoading}
                    {...form.getInputProps('name')}
                    required
                />
                <TextInput
                    label="Email"
                    placeholder="hola@forvet.org"
                    disabled={isLoading}
                    {...form.getInputProps('email')}
                    required
                />
            </SimpleGrid>
            <PhoneInputField
                ref={ref}
                name="phone"
                fieldError={form.errors.phone}
                {...form.getInputProps("phone")}
            />
            <Textarea
                mt="md"
                label="Mensaje"
                placeholder="Buenos dias, quería consultar por..."
                minRows={3}
                disabled={isLoading}
                {...form.getInputProps('message')}
            />

            <Group justify="flex-end" mt="md">
                <Button type="submit" loading={isLoading}>
                    Enviar mensaje
                </Button>
            </Group>
        </form>
    );
}

export default ContactForm;